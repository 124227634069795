<template>

  <ur-page-container class="msp" :show-title="true" title="전화이력조회" :topButton="false" @on-header-left-click="fn_back">
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap">
      <div v-if="callList.length > 0" class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="headerview">
          <!-- collapsing 영역 -->
          <template #collapsing>
            <div class="h1px" />
          </template>

          <!-- stickey 영역 -->
          <template #sticky>
          </template> 

          <!-- scroll 영역 -->
          <template #scroll>
            <ur-box-container alignV="start" componentid="" direction="column" class="status-list type-expand list-pa2024 bd-b-Ty1">     
              <mo-list-item v-for="(list,idx) in callList" :key="idx"> 
                <!-- msp-expand 에 맨 첫번째 값만 펼침 일 겨우 첫번째에 expanded 넣어주세요! -->
                <msp-expand btn-area-first title-first expand-only-btn expanded class="mo-list-expand" btn-area-class="fexTy3 align-item-start">
                  <template #title>
                    <div class="list-item__contents">
                      <div class="list-item__contents__title">
                        <span class="name txtSkip fs19rem">{{list.dataYear}}</span>
                      </div>
                    </div>
                  </template>
                  <template #btn>
                      <mo-button class="link-arrow down"></mo-button>
                  </template>
                  <template #content>
                    <div class="full-list mt20">
                      <div class="list-item__contents con-area mt10">
                        <div v-for="(data,idx2) in list.dataList" :key="idx2" class="item-box mt10" >                  
                          <ur-box-container alignV="start" componentid="" direction="column" class="round-box pa1216" :class="{'bgcolor-1' : idx2 % 2 === 0, 'bgcolor-blue' : idx2 % 2 !== 0}">
                            <div class="full fs16rem roundbox-side-inner fex-clumn">
                              <b class="min60 t-2">{{data.callDate}}</b>
                              <div class="fexTy3">
                                <span class="fwb">{{data.mobslCustCntcId == 'HC10' ? '계약상담전화' : '상품소개전화'}}</span>
                                <mo-button class="ns-btn-round blackWH sm" @click="fn_memoSave(data.mobslCntcCheckSn, data.custumTime, data.phclMemoCntnt)">{{ data.phclMemoCntnt ? '메모수정' : '메모작성'}}</mo-button>
                              </div>
                              <span>{{data.custumTime}}</span>
                              <span class="mt6  " v-if="data.phclMemoCntnt">{{data.phclMemoCntnt}}</span>
                            </div>
                          </ur-box-container>
                        </div>  
                      </div>
                    </div>
                  </template>
                </msp-expand>
              </mo-list-item>  
            </ur-box-container>  
          </template>
        </mo-collapsing-header-view>
      </div>

      <!-- NoData 영역: start  -->
      <ur-box-container v-else alignV="start" componentid="ur_box_container_002" direction="column" class="ns-contract-list no-data">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__info">
              <span>데이터가 없습니다.</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
      <!-- NoData 영역: end  -->
    </ur-box-container>
  </ur-page-container>

</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import Msg from '@/systems/webkit/msg/msg'
  import MSPCM139P from '@/ui/cm/MSPCM139P' // 전화메모 추가, 수정
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCM290D",
    screenId: "MSPCM290D",
    components: {},
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_back)
    },
    mounted() {
      console.log('MSPCM290D mounted', this.$route.params)
      this.rsltBasSVO = this.$route.params.rsltBasSVO
      this.userInfo = this.$route.params.userInfo
      this.fn_callHis()

    },
    beforeDestroy () {
    // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_back)
  },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        rsltBasSVO: {},
        userInfo: {},
        callList: [],
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      fn_callHis(reload, flag) {
        console.log('전화이력조회!!!', this.rsltBasSVO)
        const lv_Vm = this 
        const trnstId = 'txTSSCM58S1' 
        const auth = 'S'

        let pParams = {
          // mobslCntcCheckSn: this.pMobslCntcCheckSn,
          mobslChnlCustId: this.rsltBasSVO.chnlCustId,
          mobslCnsltEno:this.getStore('userInfo').getters.getUserInfo.userId,
        }
        console.log('param>>!', pParams)
        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
            console.log('이력조회 결과', response)
            if (response?.body && response.body.cmcustPhclMemoSVO) {
              console.log('이력조회 성공')
              if (response.body.cmcustPhclMemoSVO.length > 0) {
                lv_Vm.fn_listCustum(response.body.cmcustPhclMemoSVO)
                if (reload) {
                  console.log('리로드~~~~~', flag)
                  setTimeout(function () {
                    let alertProps = {
                        title: '',
                        msg: flag ? '수정되었습니다.' : '저장되었습니다.'
                  }
                    lv_Vm.$cmUtil.fn_OpenAlert(lv_Vm, alertProps)
                  },1000)
                }
              }
              // lv_Vm.$emit('onPopupConfirm')
            } else {
              let msg = response.msgComm.msgDesc
              let alertProps = {
                      title: '',
                      msg: msg
                }
                lv_Vm.$cmUtil.fn_OpenAlert(lv_Vm, alertProps)
            }
          })
      },

      fn_back() {
        console.log('뒤로가기 클릭', this.$router)
        this.$router.replace({ name: 'MSPCM270M', params:{chnlCustId: this.rsltBasSVO.chnlCustId, cnsltNo: this.getStore('userInfo').getters.getUserInfo.userId}})
      },

      fn_listCustum(list) {
        console.log('fn_listCustum>>', list)
        const tmp = this.$bizUtil.cloneDeep(list)
        let rtnList = []
        tmp.forEach(item => {
          let dataYear = item.erdat.substr(0,4)
          let sameYearList = rtnList.filter(item => item.dataYear === dataYear)
          if (sameYearList.length < 1) {
            let dataList = tmp.filter(item => {
                item.callMonth = item.erdat.substr(4,2)
                item.callDate = item.erdat ? item.callMonth + '-' + item.erdat.substr(6,2) : ''
                let phclTmArray = item.mobslCntcCntnt.split(',')
                item.custumTime = `${phclTmArray[0]} (${phclTmArray[1]})`
                // item.custumTime = this.convertTime(item.erzet.substring(0,4))
                
                return item.erdat.indexOf(dataYear) > -1
              });
            rtnList.push({dataYear, dataList})
          }
        });
        console.log('rtnList>>><>', rtnList)
        this.callList = rtnList
      },

      // convertTime(_data) {
      //   let ret_val = ''
      //   if ( _data === null || _data.length !== 4 ) {
      //     ret_val = '종일'
      //   } else {
      //     if ( Number( _data.substring(0, 2)) < 12 ) {
      //       ret_val = '오전 ' + _data.substring(0, 2) + ':' + _data.substring(2, 4)
      //     } else if ( Number( _data.substring(0, 2)) > 12 ) {
      //       ret_val = '오후 ' + ( Number(_data.substring(0, 2)) - 12 ) + ':' + _data.substring(2, 4)
      //     } else {
      //       ret_val = '오후 ' + '12' + ':' + _data.substring(2, 4)
      //     }
      //   }
      //   return ret_val
      // },
      fn_memoSave(CheckSn, _showTime, memo) {
        let lv_Vm = this
        // let popup139 = this.$bottomModal.open(MSPCM139P_copy, {
        let popup139 = this.$bottomModal.open(MSPCM139P, {
          properties: {
            pMobslCntcCheckSn: CheckSn,
            pChnlCustId: this.rsltBasSVO.chnlCustId,
            custNm: this.rsltBasSVO?.custNm,
            showTime: _showTime,
            memoCntnt: memo
          },
          listeners: {
            onPopupClose: (pData) => {
              // 모달 닫기
              console.log('MSPCM290D onPopupClose')
              this.$bottomModal.close(popup139)
            },
            onPopupConfirm: (pData) => {
              // 모달 닫기
              this.$bottomModal.close(popup139)
              this.fn_callHis(true, pData)
            },
          }
        },
        {
        properties: {
          preventTouchClose: true,
        }
      })
      }
    }
  };
</script>